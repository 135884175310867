import React, {useState,} from 'react';
import {useTranslation} from 'react-i18next';
import {isComponentVisibleForUser} from "../../../utils";
import Typography from "@mui/material/Typography";
import Edit from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import Tooltip from '@mui/material/Tooltip';
import PDSDialog from '../../../components/common/PDSDialog';
import {UC0163, UC0164} from '../../../utils/constants';
import {styled} from "@mui/system";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import {Avatar, Chip, CircularProgress, IconButton, useTheme} from "@mui/material";
import Badge from '@mui/material/Badge';
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList as List} from "react-window";
import LicensePlate from '../../../components/common/LicensePlate';


const FetchingProgress = styled('div')(({theme}) => ({
    paddingTop: '12px',
}));

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: theme.palette.primary.main,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const Container = styled('div')(({theme}) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 100,
    paddingTop: 20,

}));

const Chips = styled('div')(({theme}) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    height: '50px',

}));


const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};


function UserList(props) {

    const {t} = useTranslation();
    const theme = useTheme();
    const {
        users,
        userUseCases,
        isLoading,
        handleUserDetail,
        table,
        handleActivate,
        confirmModalOpened,
        setConfirmModalOpened,
    } = props;

    const [userToActive, setUserToActive] = useState();
    const [actionInProgress, setActionInProgress] = useState(false);

    const xrenderUserState = (param) => {

        if (!param) {
            return <Avatar variant="outlined" title={t('USER_INACTIVE')}>
                <PersonOffIcon color="error" style={{fontSize: 40}}/>
            </Avatar>

        } else {
            if (param.active) {
                return <Avatar variant="outlined" title={t('USER_ACTIVE')}>
                    <PersonIcon style={{fontSize: 40}}/>
                </Avatar>
            } else {
                return <Avatar variant="outlined" title={t('USER_INACTIVE')}>
                    <PersonOffIcon style={{fontSize: 40}}/>
                </Avatar>
            }
        }
    }

    const renderUserState = (param) => {
        console.log(param);
        if (!param.licensePlate) {
            if (param.active) {
                return <Avatar variant="outlined" title={t('USER_ACTIVE')}>
                    <PersonIcon style={{fontSize: 40}}/>
                </Avatar>
            } else {
                return <Avatar variant="outlined" title={t('USER_INACTIVE')}>
                    <PersonOffIcon style={{fontSize: 40}}/>
                </Avatar>
            }
        } else {

            return <LicensePlate licensePlate={param.licensePlate} active={param.active}/>
        }
    }

    const renderUserAvatar = (param) => {

        if (param && param.admin) {
            return <StyledBadge
                overlap="circular"
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                badgeContent="A"
                variant="string"
                sx={{color: '#fff'}}
            >
                {renderUserState(param)}
            </StyledBadge>
        } else {
            return renderUserState(param)
        }
    }
    const renderChips = (groups) => {
        return <Chips>
            {groups && groups.filter(a => a.type === 'R').map((group, index) => {
                    return <Chip
                        style={{
                            backgroundColor: group.lightColor,
                            color: 'black',
                            marginLeft: '5px',
                            marginBottom: '5px',
                        }}
                        variant="outlined"
                        label={group.name}
                    />
                }
            )
            }
        </Chips>
    }

    const renderRoleAdmin = (param) => {
        if (param && param.admin) {
            return <Tooltip title={t('USER_ADMIN')}><SecurityIcon color="secondary" style={{fontSize: 40}}/></Tooltip>
        }
    }

    const renderRoleAdminTable = (param) => {
        if (param && param.admin) {
            return <>{t('YES')}</>
        } else {
            return <>{t('NO')}</>
        }
    }

    const renderRoleName = (param) => {
        if (param && param.roles) {
            let roles = '';
            for (let i = 0; i < param.roles.length; i++) {
                if (roles === '') {
                    roles = param.roles[i].name;
                } else {
                    roles += ',' + param.roles[i].name;
                }
            }
            return <>{roles}</>
        }
    }

    const CARD_SIZE = 400;


    const Row = ({index, data, style}) => {

        const {users, itemsPerRow} = data;

        if (!users) {
            return null;
        }

        const items = [];
        const fromIndex = index * itemsPerRow;

        const toIndex = Math.min(
            fromIndex + itemsPerRow,
            users.length
        );

        for (let i = fromIndex; i < toIndex; i++) {
            let userItem = users[i];
            items.push(
                <Paper key={userItem.userId}
                       elevation={0}
                       sx={{
                           width: CARD_SIZE,
                           height: 150,
                           padding: 1,
                           margin: 1,
                           backgroundColor: (theme) => theme.palette.background.paper,
                           boxShadow: `8px 8px 20px 3px ${theme.palette.shadow}`
                       }}
                >
                    <Grid container alignItems="center" marginLeft="0px">
                        <Grid item xs={3}>
                            <div style={{paddingLeft: '6px'}}>
                                {renderUserAvatar(userItem)}
                            </div>
                            {/* <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    {renderUserAvatar(userItem)}
                            </Box>
                            </Box>
                            <LicensePlate LicensePlate={userItem.licencePlate}/>

                            */}
                        </Grid>
                        <Grid item xs={8}>
                            <Tooltip title={`${t('NAME')} - ${t('EMAIL')}`}>
                                <Typography marginLeft="10px"
                                            marginTop="3px"
                                            onClick={() => {
                                                isComponentVisibleForUser(UC0164, userUseCases) && handleUserDetail(userItem, false, true)
                                            }}
                                            sx={
                                                {cursor: isComponentVisibleForUser(UC0164, userUseCases) ? "pointer" : "default"}
                                            }

                                            variant="body1">{(userItem.firstName && userItem.firstName.length > 0) || (userItem.lastName && userItem.lastName) ? (userItem.firstName && userItem.firstName.length > 0 ? userItem.firstName + ' ' : '') + (userItem.lastName && userItem.lastName.length > 0 ? userItem.lastName : '') : userItem.userName}
                                </Typography>

                                <Typography marginLeft="10px"
                                            variant="body2">{userItem.email}</Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                            {isComponentVisibleForUser(UC0163, userUseCases) &&
                                <IconButton color="primary" aria-label="edit" size="small" label={t('EDIT')}
                                            onClick={() => {
                                                handleUserDetail(userItem, false, false)
                                            }}>
                                    <Edit fontSize="small"/>
                                </IconButton>}

                        </Grid>
                        <Grid item xs={12} sx={{paddingTop: 2}}>
                            <Tooltip title={t('GROUP')}>
                                {renderChips(userItem.groups)}
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>)
        }

        return (

            <div style={style}>
                <div style={{display: 'flex', padding: '20px', justifyContent: "flex-start", height: '100%'}}>
                    {items}
                </div>
            </div>
        )
    }


    return (
        <div style={{marginTop: "10px", height: "calc(100vh - 200px)"}}>
            {confirmModalOpened
                && (
                    <PDSDialog
                        open={confirmModalOpened}
                        title={`${userToActive.active ? t('USER_INACTIVATION') : t('USER_ACTIVATION')}`}
                        onClose={() => {
                            setConfirmModalOpened(false);
                            setUserToActive(null);
                        }}
                        size="lg"
                        headerColor='#5985EE'
                        height={100}
                        onSubmit={() => {
                            handleActivate(userToActive);
                        }}
                        actionType={userToActive.active ? 'userDeactivate' : 'roleActivate'}
                    >

                        <div>
                            {userToActive.active ? t('CONFIRM_USER_DEACTIVE', {userName: userToActive.userName}) : t('CONFIRM_USER_ACTIVE', {userName: userToActive.userName})}
                        </div>
                    </PDSDialog>
                )}
            <AutoSizer>
                {({height, width}) => {
                    const itemsPerRow = Math.floor(width / CARD_SIZE) || 1; // A calculation to establish how many cards will go on each row.

                    // The || 1 part is a simple hack that makes it work in a really small viewport (if someone totally collapses the window)

                    const rowCount = Math.ceil(users.length / itemsPerRow); // List will need the number of rows in order to be able to properly know what to render and what not to
                    return (
                        <div>
                            <List
                                height={height}
                                itemCount={rowCount}
                                itemData={{itemsPerRow, users}}
                                itemSize={170}
                                width={width}
                            >
                                {Row}


                            </List>

                        </div>
                    )
                        ;
                }}
            </AutoSizer>

        </div>
    );
}

export default UserList;