import Reservation from './containers/reservation'
import {Navigate, Route, Routes} from 'react-router-dom';
import {styled} from '@mui/system';
import Theme from "./theme";
import React, {useState} from "react";
import UserAdministration from "./containers/administration/UserAdministration";
import AssetAdministration from "./containers/administration/AssetAdministration";
import AllReservation from "./containers/reservation/AllReservation";
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import Menu from "./components/layout/Menu";
import Paper from "@mui/material/Paper";
import GroupAdministration from "./containers/administration/GroupAdministration";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { ASSET_TYPE_CAR, ASSET_TYPE_PLACE, UC0002, UC0003, UC0004, UC0005, UC0006, UC0007, UC0008 } from './utils/constants';
import { isComponentEnabledForUser } from './utils';
import Setting from './containers/administration/Setting';


const Root = styled('main')(({theme}) => ({
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    overflow: 'hidden',

}));

const Content = styled(Box)(({theme}) => ({
    height: '100vh',
    [theme.breakpoints.only('xs')]: {
        width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
        width: 'calc(100% - 200px)',
    },
    backgroundColor: theme.palette.background.default,

}));

const ListDiv = styled('div')({
    width: 150,
});

const RightMenu = styled(Paper)(({theme, shown}) => {
        return {
            zIndex: 500,
            [theme.breakpoints.only('xs')]: {
                display: shown ? 'block' : 'none',
                width: shown ? '350px' : '0px',

            },
            [theme.breakpoints.up('sm')]: {
                width: '18%',

            },
            height: '100vh',
            position: 'absolute',
            right: 0,
            top:0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,

        }
    })
;


const Hamburger = styled(MenuIcon)(({theme, shown}) => ({
    position: 'absolute',
    top: '45px',
    right: '50px',
    zIndex: 4000,
    [theme.breakpoints.only('xs')]: {
        display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },

}));


function App(props) {

    const {userUseCases} = props;

    const [mode, setMode] = useState(localStorage.getItem('mode') || 'light');

    const handleModeChange = (val) => {
        if (val.target.checked) {
            setMode('dark');
        } else {
            setMode('light');
        }
    }
    const [shown, setShown] = React.useState(false);
    const toggleDrawer = (open) => (event) => {
        setShown(!shown);
    };

    return (
        <Theme mode={mode}>
            <Root>
                <Hamburger onClick={toggleDrawer(true)}></Hamburger>
                <Content>
                    <Routes>
                        <Route path={`/${process.env.REACT_APP_PATH}`}
                               element={<Navigate to={`/${process.env.REACT_APP_PATH}/reservation`}/>}/>
                        <Route exact path={'/'} element={<Reservation/>}/>
                        <Route exact path={`/${process.env.REACT_APP_PATH}/reservation`}
                               element={<Reservation/>}/>
                        
                        { isComponentEnabledForUser(UC0003, userUseCases) &&
                        <Route exact path={`/${process.env.REACT_APP_PATH}/administration/reservation`}
                               element={<AllReservation/>}/>
                        }
                        { isComponentEnabledForUser(UC0005, userUseCases) &&
                        <Route exact path={`/${process.env.REACT_APP_PATH}/administration/placeList`}
                               element={<AssetAdministration type={ASSET_TYPE_PLACE}/>}/>
                        }
                        { isComponentEnabledForUser(UC0004, userUseCases) &&
                        <Route exact path={`/${process.env.REACT_APP_PATH}/administration/userlist`}
                               element={<UserAdministration/>}/>
                        }
                        { isComponentEnabledForUser(UC0006, userUseCases) &&
                        <Route exact path={`/${process.env.REACT_APP_PATH}/administration/grouplist`}
                               element={<GroupAdministration/>}/>
                        }
                        { isComponentEnabledForUser(UC0007, userUseCases) &&
                        <Route exact path={`/${process.env.REACT_APP_PATH}/administration/setting`}
                               element={<Setting />}/>
                        }
                        { isComponentEnabledForUser(UC0008, userUseCases) &&
                        <Route exact path={`/${process.env.REACT_APP_PATH}/administration/carList`}
                               element={<AssetAdministration type={ASSET_TYPE_CAR}/>}/>
                        }
                    </Routes>
                </Content>
                <RightMenu elevation={1} shown={shown}>
                    <Menu handleModeChange={handleModeChange} mode={mode}/>
                </RightMenu>
            </Root>
        </Theme>
    )
        ;
}

App.propTypes = {
};

App.defaultProps = {
};

const mapStateToProps = (store) => ({
    userUseCases: store.authData.userUseCases,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);


