import types from '../actionTypes';
import reservationService from '../../services/reservation.service';

export const createReservation = (reservation) => (dispatch, getState) => {
    if (getState().reservationData.isCreating) {
        return Promise.reject();
    }

    dispatch({
        type: types.RESERVATION_CREATE_REQUEST,
    });

    return reservationService.createReservation(reservation)
        .then(() => {
            dispatch({
                type: types.RESERVATION_CREATE_SUCCESS,
                payload: {reservation},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RESERVATION_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const validateReservation = (reservation) => (dispatch, getState) => {

    if (getState().reservationData.isValidating) {
        return Promise.reject();
    }

    dispatch({
        type: types.RESERVATION_VALIDATE_REQUEST,
    });

    return reservationService.validateReservation(reservation)
        .then(() => {
            dispatch({
                type: types.RESERVATION_VALIDATE_SUCCESS,
                payload: {reservation},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RESERVATION_VALIDATE_FAIL,
                payload: {error},
            });

            throw error;
        });

}

export const updateReservation = (reservation) => (dispatch, getState) => {
    if (getState().reservationData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.RESERVATION_UPDATE_REQUEST,
    });

    return reservationService.updateReservation(reservation)
        .then(() => {
            dispatch({
                type: types.RESERVATION_UPDATE_SUCCESS,
                payload: {reservation},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RESERVATION_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const deleteReservation = (reservationId) => (dispatch, getState) => {
    if (getState().reservationData.isUpdating) {
        return Promise.reject();
    }

    dispatch({
        type: types.RESERVATION_DELETE_REQUEST,
    });

    return reservationService.deleteReservation(reservationId)
        .then(() => {
            dispatch({
                type: types.RESERVATION_DELETE_SUCCESS,
                payload: {},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RESERVATION_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const fetchReservations = (dateFrom, dateTo, userId) => (dispatch, getState) => {
    if (getState().reservationData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.RESERVATION_FETCH_REQUEST,
    });

    let res = userId != null ? reservationService.getReservationsForUser(userId) : reservationService.getReservations();
    return res.then((reservations) => {
        dispatch({
            type: types.RESERVATION_FETCH_SUCCESS,
            payload: {reservations},
        });

        return true;
    })
        .catch((error) => {
            dispatch({
                type: types.RESERVATION_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};
