import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import * as React from "react";
import {
    dayjsToDateString,
    dayjsToDateTimeString,
    dayjsToTimeString,
    isComponentEnabledForUser,
    isComponentVisibleForUser
} from "../../utils";
import {ASSET_TYPE_PLACE, RESERVATION_STATUSES, UC0101, UC0102, UC0106, UC0107} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton/IconButton";
import Edit from "@mui/icons-material/Edit";
import {styled} from "@mui/system";
import {useTheme} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import LicensePlate from "../../components/common/LicensePlate";
import DeleteIcon from '@mui/icons-material/Delete';

const Timewrapper = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: '30',

}));

const Attachment = styled('img')(({theme}) => ({}));

const getBackgroundColor = (reservation, theme, withContrast) => {
    if (reservation.status === RESERVATION_STATUSES.PLANNED) {
        return theme.palette.status[`planned${withContrast ? 'Contrast' : ''}`];
    } else if (reservation.status === RESERVATION_STATUSES.IN_PROGRESS) {
        return theme.palette.status[`inProgress${withContrast ? 'Contrast' : ''}`];
    }
    return withContrast ? theme.palette.status.oldContrast : theme.palette.background.paper;
}

const renderImg = (image, height, width, type) => {
    return <Attachment src={image?`data:image/png;base64,${image}`:(type===ASSET_TYPE_PLACE?'/misto.png':'/auto.png')} sx={{
        width: {width},
        height: 'auto',
        padding: !image?'5px':'0px'
    }}>

    </Attachment>
}

const Cool = styled('div')({
    marginBottom: '30px',
    marginTop: '30px',
});


export const ReservationDetailContent = ({
                                             all,
                                             reservation,
                                             handleEdit,
                                             handleDelete,
                                             userUseCases,
                                             height,
                                             width,
                                             size = 'sm',
                                             modal
                                         }) => {

    const {t, i18n} = useTranslation();
    const theme = useTheme();
    console.log(reservation);
    const action = (handleDelete && handleEdit) ? 4 : ((handleDelete || handleEdit) ? 1 : 0);

    return (<Grid 
        container height={height} width={width} 
        sx={{
            marginTop:'1px', borderRadius: '30px', marginLeft: modal ? '-17px' : '',
            boxShadow: modal ? `4px 4px 15px 3px ${theme.palette.shadow}` : '' }}
    >
        <Grid item xs={12-action} sx={{
            padding: 1,
            margin: 1,
        }}>
            { modal && 
                <Cool>
                    <img src='/hura.png' width='270px'/>
                </Cool>
            }

            <Typography textAlign="left" variant={size === 'lg' ? "h5" : "body1"}>
                {`${reservation.assetName}`}
            </Typography>
            <Typography textAlign="left" variant={size === 'lg' ? "h5" : "body2"}>
                {`${reservation.locationName}`}
            </Typography>
            <Typography textAlign="left" marginTop="5px" variant={size === 'lg' ? "h6" : "body1"}>
                {`${reservation.reservedByFirstName} ${reservation.reservedBySurname} `}
            </Typography>
            <Typography textAlign="left" variant={size === 'lg' ? "h6" : "body2"}>
                {`${reservation.reservedByEmail} `}
            </Typography>
            <Typography sx={{
                width: '100%', marginTop: 2,
                transform: 'translate(0, -40%)', alignItems: 'center'
            }} variant={size === 'lg' ? "h6" : "body1"}>

                <div style={{marginLeft: '2px', marginTop: '20px'}}>
                    <LicensePlate licensePlate={reservation.licensePlate} active/>
                </div>

            </Typography>

        </Grid>
        <Grid item xs={1} alignContent="center" sx={{padding: 1}}>
            {handleEdit && ((!all && isComponentVisibleForUser(UC0101, userUseCases)) || (all && isComponentVisibleForUser(UC0106, userUseCases))) &&
                `${reservation.status}` !== 'OLD' &&
                <IconButton color="primary" aria-label="edit" size="small" onClick={() => {
                    if ((!all && isComponentEnabledForUser(UC0101, userUseCases)) || (all && isComponentEnabledForUser(UC0106, userUseCases))) {
                        handleEdit(reservation);
                    }
                }}>
                    <Edit fontSize="small"/>
                </IconButton>}
        </Grid>

        <Grid item xs={1} alignContent="center" sx={{padding: 1}}>
            {handleDelete && ((!all && isComponentVisibleForUser(UC0102, userUseCases)) || (all && isComponentVisibleForUser(UC0107, userUseCases))) &&
                `${reservation.status}` === 'PLANNED' &&
                <IconButton color="primary" aria-label="delete" size="small" onClick={() => {
                    if ((!all && isComponentEnabledForUser(UC0102, userUseCases)) || (all && isComponentEnabledForUser(UC0107, userUseCases))) {
                        handleDelete(reservation.reservationId);
                    }
                }}>
                    <DeleteIcon fontSize="small"/>
                </IconButton>}
        </Grid>


        <Grid item xs={12} alignItems="center">
            <Tooltip>
                {renderImg(reservation.img, height, width, reservation.assetType)}
            </Tooltip>
        </Grid>

        <Grid container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Grid item xs={12} sx={{
                display: 'flex', justifyContent: "center", alignItems: "center", padding: 1,
                margin: 1
            }}>
                <Paper sx={{
                    backgroundColor: theme.palette.background.grey,
                    boxShadow: 'none',
                    maxWidth: '150px',
                    width: '100%',
                    marginLeft: 1,
                    paddingTop: 2,
                    paddingBottom: 2,

                }}>
                    <Timewrapper>
                        <Typography align='center'
                                    variant={size === 'lg' ? "h6" : "body2"}>{dayjsToDateTimeString(reservation.dateFromD, i18n.language)}</Typography>
                    </Timewrapper>


                </Paper>


                <Typography align='center' sx={{padding: 1}}
                            variant={size === 'lg' ? "h6" : "body1"}>{t('TO').toLowerCase()}</Typography>

                <Paper sx={{
                    backgroundColor: theme.palette.background.grey,
                    boxShadow: 'none',
                    maxWidth: '150px',
                    width: '100%',
                    paddingTop: 2,
                    paddingBottom: 2,

                }}>
                    <Timewrapper>
                        <Typography align='center'
                                    variant={size === 'lg' ? "h6" : "body2"}>{dayjsToDateTimeString(reservation.dateToD, i18n.language)}</Typography>
                    </Timewrapper>
                </Paper>

            </Grid>

        </Grid>
    </Grid>)

}


const ReservationDetail = ({
                               reservation,
                               userUseCases,
                               handleDelete,
                               handleEdit,
                               height = 'auto',
                               width = '300px',
                               all
                           }) => {

    const {t, i18n} = useTranslation();
    const theme = useTheme();


    if (!reservation) {
        return null;
    }


    return (
        <Paper
            key={reservation.reservationId}
            elevation={0}
            sx={{
                width: {width},
                height: {height},

                backgroundColor: (theme) => theme.palette.background.paper,
                // backgroundColor: (theme) => getBackgroundColor(reservation, theme, false),
                boxShadow: `8px 8px 20px 3px ${theme.palette.shadow}`
            }}
        >
            <ReservationDetailContent all={all} width={width} height={height} reservation={reservation}
                                      handleDelete={handleDelete}
                                      handleEdit={handleEdit}
                                      userUseCases={userUseCases}/>

        </Paper>
    );
}

export default ReservationDetail;