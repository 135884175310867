import {
  Grid,
  Typography,
} from "@mui/material";
import LexicalEditorWrapper from "./components/LexicalEditorWrapper";
// import "./App.css";
import {styled} from "@mui/system";

// const Container = styled('div')(({theme}) => ({
//     background: '#fff',
//     margin: '20px auto 20px auto',
//     borderRadius: '2px',
//     maxWidth: '600px',
//     color: '#000',
//     position: 'relative',
//     lineHeight: '20px',
//     fontWeight: 400,
//     textAlign: 'left',
//     borderTopLeftRadius: '10px',
//     borderTopRightRadius: '10px',
// }));

// const Placeholder = styled('div')(({theme}) => ({
//   color: '#999',
//   overflow: 'hidden',
//   position: 'absolute',
//   textOverflow: 'ellipsis',
//   top: '15px',
//   fontSize: '15px',
//   userSelect: 'none',
//   display: 'inline-block',
//   pointerEvents: 'none'
// }));


export default function CustomRtfEditor(props) {
  const {title, handleHtmlContent, placeholderText, initialHtml} = props;
  return (
    <Grid
      container
      flexDirection="column"
      alignItems="left"
      rowSpacing={1}
    >
      { title && 
        <Grid item sx={{ my: 4 }}>
          <Typography variant="h6" sx={{paddingLeft: '15px'}}>{title}</Typography>
        </Grid>
      }
      <Grid item sx={{ width: 1150, overflow: "hidden",marginLeft: '15px' }}>
        <LexicalEditorWrapper handleHtmlContent={handleHtmlContent} placeholderText={placeholderText} initialHtml={initialHtml}/>
      </Grid>
    </Grid>
  );
}

