import React from 'react';
import {useTranslation} from 'react-i18next';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {Autocomplete, Chip} from "@mui/material";
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import {ASSET_TYPE_CAR, ASSET_TYPE_PLACE, UC0152, UC0156} from "../../../utils/constants";
import IconButton from "@mui/material/IconButton/IconButton";
import Add from "@mui/icons-material/Add";
import GroupAutocomplete from "../../../components/common/GroupAutocomplete";
import LocationAutocomplete from "../../../components/common/LocationAutocomplete";
import ToolBarCommon from "../../../components/common/ToolBarCommon";


function ToolBar(props) {


    const {
        userUseCases,
        handleUserUpdate,
        selectedName,
        handleSelectedName,
        selectedStatus,
        handleSelectedStatus,
        selectedAdminRole,
        handleSelectedAdminRole,
        selectedLocation,
        handleSelectedLocation,
        handleSelectedGroup,
        locations,
        groups,
        selectedGroup,
        handleNewAsset,
        user,
        type
    } = props;

    const {t} = useTranslation();

    const statuses = [
        {
            value: 'ACTIVE',
            label: t('ACTIVE'),
            key: 'ACTIVE'
        },
        {
            value: 'INACTIVE',
            label: t('INACTIVE'),
            key: 'INACTIVE'
        },
    ]

    const admin = [
        {
            value: 'YES',
            label: t('YES'),
            key: 'YES'
        },
        {
            value: 'NO',
            label: t('NO'),
            key: 'NO'
        },
    ]
    const [shown, setShown] = React.useState(true);


    return (
        <ToolBarCommon title={type===ASSET_TYPE_PLACE?t('PLACE_LIST'):t('CAR_LIST')} shown={shown} setShown={setShown}>

                <Grid item md={2} xs={11} sx={{ display: shown ? 'flex' : {'xs': 'none', 'lg': 'flex'}}}>
                    <TextField
                        variant="outlined"
                        type="search"
                        label={type===ASSET_TYPE_PLACE?t('PLACE_NAME'):t('CAR_NAME')}
                        fullWidth
                        value={selectedName || ''}
                        onChange={handleSelectedName}/>

                </Grid>
                <Grid item md={2} xs={6} sx={{ display: shown ? 'flex' : {'xs': 'none', 'lg': 'flex'},}}>
                    <Autocomplete
                        multiple
                        value={selectedStatus}
                        onChange={(event, newValue) => {
                            handleSelectedStatus(newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        id="statuses"
                        style={{width: '100%'}}
                        options={statuses}
                        getOptionLabel={(option) => {
                            return option.label;
                        }}
                        renderInput={(params) => (
                            <TextField variant="outlined" {...params} label={t('STATE')} fullWidth/>
                        )}
                        renderTags={(value, getTagProps) => (
                            value.map((option, index) => {
                                    return <Chip
                                        style={{
                                            backgroundColor: option.color,
                                            color: 'black'
                                        }}
                                        variant="outlined"
                                        label={option.label}
                                        {...getTagProps({index})}
                                    />
                                }
                            )
                        )
                        }
                    />
                </Grid>

                <Grid item md={3} xs={6} sx={{ display: shown ? 'flex' : {'xs': 'none', 'lg': 'flex'},}}>
                    <LocationAutocomplete
                        locations={locations}
                        setLocations={handleSelectedLocation}
                        selectedLocations={selectedLocation}
                    />

                </Grid>


                <Grid item md={4} xs={10} sx={{ display: shown ? 'flex' : {'xs': 'none', 'lg': 'flex'},}}>
                    <GroupAutocomplete selectedGroups={selectedGroup} groups={groups} setGroups={handleSelectedGroup}/>

                </Grid>
                <Grid item md={1} xs={shown ? 2:11} sx={{display: 'flex', paddingTop: '0 !important', justifyContent: 'flex-end'}}>
                    {type===ASSET_TYPE_PLACE && isComponentVisibleForUser(UC0152, userUseCases) && <div>
                        <IconButton
                            edge="start"
                            aria-label="add place"
                            onClick={handleNewAsset}
                            size="large"
                            disabled={!isComponentEnabledForUser(UC0152, userUseCases)}>
                            <Add/>
                        </IconButton>
                    </div>
                    }
                    {type===ASSET_TYPE_CAR && isComponentVisibleForUser(UC0156, userUseCases) && <div>
                        <IconButton
                            edge="start"
                            aria-label="add card"
                            onClick={handleNewAsset}
                            size="large"
                            disabled={!isComponentEnabledForUser(UC0156, userUseCases)}>
                            <Add/>
                        </IconButton>
                    </div>
                    }
                </Grid>

        </ToolBarCommon>
    );
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

export default ToolBar;
