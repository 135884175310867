import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Grid from "@mui/material/Grid";
import {styled} from "@mui/system";
import {Chip, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography} from "@mui/material";
import CustomTable from "../../common/CustomTable";
import {
    dayjsToDateString,
    dayjsToDateTimeString,
    isComponentEnabledForUser,
    isComponentVisibleForUser
} from "../../../utils";
import CustomDateTimePicker from "../../common/CustomDateTimePicker";
import GroupAutocomplete from "../../common/GroupAutocomplete";
import dayjs from "dayjs";
import {useDropzone} from "react-dropzone";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomTimePicker from "../../common/CustomTimePicker";
import {UC0152} from "../../../utils/constants";
import IconButton from "@mui/material/IconButton/IconButton";
import Add from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

const FormFields = styled('div')(({theme}) => ({
    height: '100%',
    width: '100%',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingTop: '50px',

}));

const EditorWrapper = styled('div')(({theme}) => ({
    height: '100%',
    width: '100%',
    padding: theme.spacing(3.25, 4, 4, 4),

}));


const ErrorMessage = styled('div')(({theme}) => ({
    fontSize: 13,

}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false,
    rowStyle: (rowdata) => {
        return {
            backgroundColor: rowdata.active ? "#fff" : 'rgba(252,234,234,0.37)',
        };
    },

};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginRight: 4,
    width: 90,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function renderGroupName(rowData) {
    return <Chip
        style={{
            backgroundColor: rowData.lightColor,
            color: 'black'
        }}
        variant="outlined"
        label={rowData.label}
    />;
}

const columns = (t, locale, groups) => [
    {
        title: t('GROUP'),
        field: 'group',
        headerStyle: {textAlign: 'left', paddingLeft: 5},
        cellStyle: {
            ...cellStyle,
            align: 'left',
        },
        render: (rowData) => (
            renderGroupName(rowData)
        ),
        editComponent: props => {
            const value = props.rowData.key ? {
                key: props.rowData.key,
                value: props.rowData.key,
                label: props.rowData.label
            } : props.value !== undefined ? props.value : null;
            return <GroupAutocomplete multiple={false} selectedGroups={value} setGroups={props.onChange}
                                      groups={groups}/>
        }
    },
    {
        title: t('FROM'),
        field: 'from',
        headerStyle: {textAlign: 'left', paddingLeft: 5},
        cellStyle: {
            ...cellStyle,
            align: 'left',
        },
        render: (rowData) => {
            return rowData.dateFromD ? dayjsToDateTimeString(rowData.dateFromD, locale) : t('N_A')
        },
        initialEditValue: new dayjs(),
        editComponent: props => {
            const value = props.rowData.dateFromD ? props.rowData.dateFromD : null;
            return (<CustomDateTimePicker dateTime={value}
                                          setDateTime={props.onChange}/>)
        }
    },
    {
        title: t('TO'),
        field: 'to',
        headerStyle: {textAlign: 'left', paddingLeft: 5},
        cellStyle: {
            ...cellStyle,
            align: 'left',
        },
        render: (rowData) => {
            if (rowData.dateToD != null) {
                return dayjsToDateTimeString(rowData.dateToD, locale);
            } else {
                return t('N_A')
            }
        },
        editComponent: props => (<CustomDateTimePicker dateTime={props.value}
                                                       setDateTime={props.onChange}
            ></CustomDateTimePicker>
        )
    }

];


function AssetReleaseInner(props) {

    const {
        userUseCases,
        groups,
        handleChange,
        groupsToBeAdded,
        error,
        readOnly,
        updatedAsset,
        setUpdatedAsset,
        addGroupsToAsset,
        isValid,
        removeGroupFromAsset,
        dates
    } = props;


    const {t, i18n} = useTranslation();
    const [data, setData] = useState();



    return (
        <FormFields>
            <form autoComplete='off'>
                <Grid item xs={12}>
                    <Typography align="left" variant='h6'
                                style={{paddingRight: 20}}>{t('ASSIGN_GROUP')}</Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">{t('FOR_HOW_LONG')}</FormLabel>
                            <RadioGroup
                                row={false}
                                aria-labelledby="for how long"
                                name="interval"
                                value={groupsToBeAdded.interval}
                                onChange={handleChange('interval')}
                            >
                                <FormControlLabel value="1DAY" control={<Radio/>}
                                                  label={`${t('1DAY')} (${dayjsToDateString(dates.date1From, i18n.language)} - ${dayjsToDateString(dates.date1To, i18n.language)} )`}/>
                                <FormControlLabel value="3DAY" control={<Radio/>}
                                                  label={`${t('3DAY')} (${dayjsToDateString(dates.date2From, i18n.language)} - ${dayjsToDateString(dates.date2To, i18n.language)} )`}/>
                                <FormControlLabel value="7DAY" control={<Radio/>}
                                                  label={`${t('7DAY')} (${dayjsToDateString(dates.date3From, i18n.language)} - ${dayjsToDateString(dates.date3To, i18n.language)} )`}/>
                                <FormControlLabel value="CUSTOM" control={<Radio/>}
                                                  label={t('CUSTOM')}/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>


                    {groupsToBeAdded.interval === 'CUSTOM' && <Grid item
                                                                    xs={12} md={3}
                                                                    sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between'
                                                                    }}>
                        <Typography variant="subtitle1" sx={{paddingRight: '10px'}}>
                            {t('FROM')}
                        </Typography>
                        <CustomDatePicker
                            date={groupsToBeAdded.dateFrom}
                            setDate={handleChange('dateFrom')}
                            error={error.dateFrom}
                        />
                        <CustomTimePicker
                            time={groupsToBeAdded.dateFrom}
                            setTime={handleChange('timeFrom')}
                            error={error.dateFrom}
                            sx={{paddingLeft: '10px'}}
                        />
                    </Grid>}
                    {groupsToBeAdded.interval === 'CUSTOM' &&
                        <Grid item
                              xs={12} md={3}
                              sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography variant="subtitle1" sx={{paddingRight: '16px'}}>
                                {t('TO')}
                            </Typography>
                            <CustomDatePicker
                                date={groupsToBeAdded.dateTo}
                                setDate={handleChange('dateTo')}
                                error={error.dateTo}
                            />

                            <CustomTimePicker
                                time={groupsToBeAdded.dateTo}
                                setTime={handleChange('timeTo')}
                                error={error.dateTo}
                            />

                        </Grid>}

                    <Grid item md={2} xs={12}>
                        <GroupAutocomplete
                            required={true}
                            groups={groups}
                            setGroups={handleChange('groups')}
                            selectedGroups={groupsToBeAdded.groups}
                            disabled={readOnly}
                            label={t('GROUP')}
                            error={error.groups}

                        />

                    </Grid>

                    <Grid item md={1} xs={6}>
                        <>
                            <Button
                                sx={{
                                    width: '100px', margin: 1
                                }}
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    isValid() && addGroupsToAsset()
                                }}
                                endIcon={<Add/>}
                            > {t('ASSIGN')}</Button>
                        </>

                    </Grid>

                    <Grid item xs={12}>
                        <Typography align="left" variant='h6'
                                    style={{paddingRight: 20}}>{t('ACTUAL_ASSIGNED_TO_GROUPS')}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTable
                            editable={{
                                isDeletable: rowData => rowData.active === true || rowData.future === true, // only name(b) rows would be deletable,
                                isDeleteHidden: rowData => rowData.active === false && rowData.future === false,
                                onRowDelete: newData => removeGroupFromAsset(newData)
                            }}
                            title=""
                            columns={columns(t, i18n.language, groups)}
                            data={updatedAsset.groups}
                            options={tableOptions}
                            loadAllData={false}
                        />
                    </Grid>


                </Grid>
            </form>
        </FormFields>
    )
        ;
}

AssetReleaseInner.propTypes = {};
AssetReleaseInner.defaultProps = {};


export default AssetReleaseInner;


