import httpService from './http.service';

const createAsset = (asset) => httpService
    .post(`/assets`, asset)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });


const addGroupToAsset = (asset, group) => httpService
    .post(`/assets/${asset}/groups`, group);


const updateAsset = (asset) => httpService
    .put(`/assets/${asset.assetId}`, asset)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const activeAsset = (assetId, active) => httpService
    .put(`/assets/${assetId}/?active=${active}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getAssets = () => httpService
    .get(`/assets?onlyAvailable=false`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const getAvailableAssets = (locationId, dateFrom, dateTo, userId, reservationId) => httpService
    .get(`/assets?userId=${userId}&reservationId=${reservationId}&onlyAvailable=true`+getParamUri(locationId, dateFrom, dateTo))
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

export default {
    getAssets,
    getAvailableAssets,
    createAsset,
    updateAsset,
    activeAsset,
    addGroupToAsset
};

const getParamUri = (locationId, dateFrom, dateTo) => {
 let result = '';
 if ( locationId ) {
    result += `&locationId=${locationId}`;
 }
 if ( dateFrom ) {
    result += `&dateFrom=${encodeURIComponent(dateFrom)}`;
 }
 if ( dateTo ) {
    result += `&dateTo=${encodeURIComponent(dateTo)}`;
 }
 return result;
}