import React from 'react';
import {useTranslation} from 'react-i18next';
import {getLocaleDateShortString} from "../../i18n";
import {styled} from "@mui/system";
import {DatePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';
import 'dayjs/locale/cs';
import 'dayjs/locale/ru';
import 'dayjs/locale/ro';
import 'dayjs/locale/pl';

const CustomDatePickerCss = styled(DatePicker)(({theme}) => ({
    width: '100%',
    marginRight: 15,
}));

export default function CustomDatePicker({
                                             date,
                                             setDate,
                                             error, 
                                             disableDateFrom
                                         }) {
    const {t , i18n} = useTranslation();

    const disableFromFce = (date) => {
        return disableDateFrom ? date < disableDateFrom : false;
    }


    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>

            <CustomDatePickerCss
                value={date}
                onChange={(val) => setDate(val)}
                desktopModeMediaQuery="(min-width:600px)"
                format={getLocaleDateShortString(i18n.language)}
                slotProps={{textField: {error: !!error, helperText: error}}}
                dayOfWeekFormatter={(day) => `${day}.`}
                disablePast
                shouldDisableDate={disableFromFce}
            />
        </LocalizationProvider>
    );
}

