import React from 'react';
import {useTranslation} from 'react-i18next';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {Autocomplete, Chip} from "@mui/material";
import ToolBarCommon from "../../../components/common/ToolBarCommon";
import GroupAutocomplete from "../../../components/common/GroupAutocomplete";


function ToolBar(props) {


    const {
        userUseCases,
        handleUserUpdate,
        selectedName,
        handleSelectedName,
        selectedStatus,
        handleSelectedStatus,
        selectedAdminRole,
        handleSelectedAdminRole,
        selectedRole,
        handleSelectedRole,
        handleSelectedGroup,
        roles,
        groups,
        selectedGroup,
        user,
        selectedLicensePlate,
        setSelectedLicensePlate
    } = props;

    const {t} = useTranslation();

    const statuses = [
        {
            value: 'ACTIVE',
            label: t('ACTIVE'),
            key: 'ACTIVE'
        },
        {
            value: 'INACTIVE',
            label: t('INACTIVE'),
            key: 'INACTIVE'
        },
    ]



    const [shown, setShown] = React.useState(true);
    const toggleDrawer = (open) => (event) => {
        setShown(!shown);
    };


    return (
        <ToolBarCommon shown={shown} setShown={setShown} title={t('USER_LIST')}>

            {!shown && <Grid item xs={11} sx={{display: shown ? 'flex' : {'xs': 'flex', 'lg': 'none'}}}></Grid>}

            <Grid item xs={5} sm={3} sx={{display: shown ? 'flex' : {'xs': 'none', 'lg': 'flex'}}}>
                <TextField
                    variant="outlined"
                    type="search"
                    label={t('NAME')}
                    fullWidth
                    value={selectedName || ''}
                    onChange={handleSelectedName}/>

            </Grid>
            <Grid item xs={6} sm={2} sx={{display: shown ? 'flex' : {'xs': 'none', 'lg': 'flex'}}}>
                <Autocomplete
                    multiple
                    value={selectedStatus}
                    onChange={(event, newValue) => {
                        handleSelectedStatus(newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    id="statuses"
                    style={{width: '100%'}}
                    options={statuses}
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label={t('STATE')} fullWidth/>
                    )}
                    renderTags={(value, getTagProps) => (
                        value.map((option, index) => {
                                return <Chip
                                    style={{
                                        backgroundColor: option.color,
                                        color: 'black'
                                    }}
                                    variant="outlined"
                                    label={option.label}
                                    {...getTagProps({index})}
                                />
                            }
                        )
                    )
                    }
                />
            </Grid>
            <Grid item sm={2} xs={6} sx={{ display: shown ? 'flex' : {'xs': 'none', 'lg': 'flex'}}}>
                <TextField
                    variant="outlined"
                    type="search"
                    label={t('LICENSE_PLATE')}
                    fullWidth
                    value={selectedLicensePlate || ''}
                    onChange={(event) => setSelectedLicensePlate(event.target.value)}/>
            </Grid>
            <Grid xs={6} item sm={3} sx={{display: shown ? 'flex' : {'xs': 'none', 'lg': 'flex'}}}>
                <Autocomplete
                    multiple
                    value={selectedRole}
                    onChange={(event, newValue) => {
                        handleSelectedRole(newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    filterSelectedOptions
                    id="role"
                    style={{width: '100%'}}
                    options={roles || []}
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    renderInput={(params) => (
                        <TextField variant="outlined" {...params} label={t('ROLE')} fullWidth/>
                    )}
                    renderTags={(value, getTagProps) => (
                        value.map((option, index) => {
                                return <Chip
                                    style={{
                                        backgroundColor: option.color,
                                        color: 'black'
                                    }}
                                    variant="outlined"
                                    label={option.label}
                                    {...getTagProps({index})}
                                />
                            }
                        )
                    )
                    }
                />
            </Grid>


            <Grid xs={12} item sm={2} sx={{display: shown ? 'flex' : {'xs': 'none', 'lg': 'flex'}}}>
                <GroupAutocomplete groups={groups} setGroups={handleSelectedGroup} selectedGroups={selectedGroup}>

                </GroupAutocomplete>

            </Grid>

        </ToolBarCommon>
    );
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

export default ToolBar;
