export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";
export const DATE_FORMAT = "YYYY-MM-DD";

export const RESERVATION_STATUSES= {
    PLANNED:"PLANNED",
    IN_PROGRESS:"IN_PROGRESS",
    OLD:"OLD"
}

export const MY_PLACE_STATUSES= {
    DRIVER:"DRIVER",
    AVAILABLE_FOR_OTHER:"AVAILABLE_FOR_OTHER",
    ASSIGNED:"ASSIGNED"
}

export const UC0002 = "UC0002";  // moje rezervace
export const UC0003 = "UC0003";  //  vsechny rezervace
export const UC0004 = "UC0004";  //  uzivaci
export const UC0005 = "UC0005";  //  mista
export const UC0006 = "UC0006";  //  skupiny
export const UC0007 = "UC0007";  //  nastaveni
export const UC0008 = "UC0008";  //  auta

export const UC0100 = "UC0100";  // Vytvorit rezervaci
export const UC0101 = "UC0101";  // Upravit rezervací

export const UC0102 = "UC0102";  // Smazani rezervací

export const UC0105 = "UC0105";  // Vytvorit rezervace pro vsechny
export const UC0106 = "UC0106";  // Úprava rezervace pro vsechny

export const UC0107 = "UC0107";  // Smazani rezervace pro vsechny


export const UC0152 = "UC0152";  // Založení mista
export const UC0153 = "UC0153";  // Úprava mista
export const UC0154 = "UC0154";  // Detail mista
export const UC0155 = "UC0155";  // Deaktivace mista

export const UC0156	= "UC0156";  // Založení auta
export const UC0157	= "UC0157";  // Úprava auta
export const UC0158	= "UC0158";  // Detail auta
export const UC0159	= "UC0159";  // Deaktivace auta

export const UC0161 = "UC0161";  // Seznam uživatelů
export const UC0162 = "UC0162";  // Založení uživatele
export const UC0163 = "UC0163";  // Úprava uživatele
export const UC0164 = "UC0164";  // Detail uživatele
export const UC0165 = "UC0165";  // Deaktivace uživatele

export const UC0170 = "UC0170";  // Zalozeni skupiny
export const UC0171 = "UC0171";  // Uprava skupiny
export const UC0172 = "UC0172";  // Seznam skupin


export const PLACE_MANAGER = 'Place Manager';

export const ASSET_TYPE_PLACE = "ASSET_PLACE"
export const ASSET_TYPE_CAR = "ASSET_CAR";