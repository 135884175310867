import React from 'react';

import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import {colors} from "@mui/material";
import {fontSize} from '@mui/system';


export const themeOptionsLight = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
        ].join(','),
        h4: {
            fontSize: 25,
            fontWeight: 400
        },
        body1: {fontSize: 14, fontWeight: 500},
        body2: {fontSize: 10},

    },
    shape: {
        borderRadius: 20,
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#5b97ce',
            selection: '#FFF',
        },
        secondary: {
            main: 'rgba(81,177,72, 1)',
            mainMarked: 'rgba(81,177,72, 0.6)',
            selection: '#000',

        },
        status: {
            planned: '#daff29',
            plannedContrast: '#c8f11c',
            inProgress: 'rgba(245,173,29,0.87)',
            inProgressContrast: 'rgba(220,152,11,0.87)',
            old: 'rgba(47,47,51,0.37)',
            oldContrast: 'rgba(7,7,7,0.37)',

        },
        background: {
            default: colors.grey[50],
            grey100: colors.grey[100],
            grey200: colors.grey[200],
            paper: colors.common.white,
            grey: '#e7e7e7',
            selected: '#51b148',
        },
        shadow: '#d3d3d3',
    },
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
              label: {
                  fontSize: '10px',
              }
            }
        }
    }

});

export const themeOptionsDark = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
        ].join(','),
        h4: {
            fontSize: 41,
            fontWeight: 600
        },
        body1: {fontSize: 14, fontWeight: 500},
        body2: {fontSize: 10},

    },
    shape: {
        borderRadius: 20,
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#5b97ce',
            selection: '#000',


        },
        secondary: {
            main: 'rgba(81,177,72, 1)',
            mainMarked: 'rgba(81,177,72, 0.6)',
            selection: '#FFF',

        },
        status: {
            planned: '#daff29',
            plannedContrast: '#c8f11c',
            inProgress: 'rgba(245,173,29,0.87)',
            inProgressContrast: 'rgba(220,152,11,0.87)',
            old: 'rgba(47,47,51,0.37)',
            oldContrast: 'rgba(7,7,7,0.37)',


        },
        shadow: '#1e1e1e',
    },
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '10px',
                }
            }
        }
    }
});

export default props => (
    <ThemeProvider theme={props.mode === 'dark' ? themeOptionsDark : themeOptionsLight}>
        <CssBaseline enableColorScheme/>

        {props.children}

    </ThemeProvider>
);
