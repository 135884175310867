import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import UserUpdate from "./UserUpdate";
import {CircularProgress, IconButton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import ClearIcon from '@mui/icons-material/Clear';
import {styled} from "@mui/system";


const CloseButton = styled(IconButton)(({theme}) => ({
    left: 10,
    color: (theme) => theme.palette.primary.contrastText

}));


function User(props) {
    const {
        userUseCases,
        roles,
        groups,
        handleClose,
        saveUser,
        userConfigModel,
        severity,
        saving,
        loggedUser,
    } = props;


    const {t} = useTranslation();

    const [updatedUser, setUpdatedUser] = useState();
    const [errorInner, setErrorInner] = useState('');

    useEffect(() => {
        if (userConfigModel.user)
            setUpdatedUser(userConfigModel.user);
    }, [userConfigModel]);

    const isValid = () => {
        let ret = true;
        const error = {};
        if (!updatedUser.firstName || updatedUser.firstName.trim().length === 0) {
            error.firstName = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (!updatedUser.lastName || updatedUser.lastName.trim().length === 0) {
            error.lastName = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (!updatedUser.userName || updatedUser.userName.trim().length === 0) {
            error.userName = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (!updatedUser.email || updatedUser.email.trim().length === 0) {
            error.email = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        console.log('error', error);
        setErrorInner(error);

        return ret;
    }


    const handleChange = (name) => (event) => {
        if (name === 'active') {
            setUpdatedUser({...updatedUser, [name]: event.target.checked === true});

        } else if (event && event.target) {
            setUpdatedUser({...updatedUser, [name]: event.target.value});
        } else {
            setUpdatedUser({...updatedUser, [name]: event});
        }
    }

    const handleSelectedRole = (data) => {
        setUpdatedUser({...updatedUser, ['roles']: data});
    }


    const handleSelectedGroup = (data) => {
        setUpdatedUser({...updatedUser, ['groups']: data});

    }

    const handleSelectedManagedGroup = (data) => {
        setUpdatedUser({...updatedUser, ['managedGroups']: data});

    }

    return (

        <Dialog
            maxWidth="xl"
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    height: '100%',
                    margin: 1,
                },
            }}
            open={userConfigModel.open}
            onClose={handleClose}
        >

            <DialogTitle sx={{
                height: '100px',
                paddingLeft: 5,
                paddingRight: 5,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                width: '100%',
            }}>
                <>
                    <Typography variant='h4'
                                sx={{color: (theme) => theme.palette.background.contrastText}}>{t(userConfigModel.readOnly ? 'DETAIL_USER' : 'UPDATE_USER')}</Typography>
                </>


                <CloseButton aria-label="delete" size="small" onClick={handleClose}>
                    <ClearIcon fontSize="medium"/>

                </CloseButton>

            </DialogTitle>


            <DialogContent>
                <UserUpdate
                    userUseCases={userUseCases}
                    readOnly={userConfigModel.readOnly}
                    handleChange={handleChange}
                    updatedUser={updatedUser}
                    error={errorInner}
                    handleSelectedRole={handleSelectedRole}
                    handleSelectedGroup={handleSelectedGroup}
                    handleSelectedManagedGroup={handleSelectedManagedGroup}
                    groups={groups}
                    roles={roles}
                />
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0, height: 100, width: '100%'
                }}>

                {saving && <CircularProgress size={24}/>}

                <Button
                    sx={{
                        width: '150px', margin: 1
                    }}
                    onClick={handleClose}
                    color="secondary"
                    variant="outlined"
                >{t('CLOSE')} </Button>

                {!userConfigModel.readOnly &&
                    <>
                        <Button
                            sx={{
                                width: '150px', margin: 1
                            }}
                            loading={saving}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                const ret = isValid();
                                if (ret) {
                                    saveUser(updatedUser);
                                }
                            }}
                        > {t('SAVE')}</Button>
                    </>
                }

            </DialogActions>


        </Dialog>

    )
        ;
}

User.propTypes = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(User);


