import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from 'react';
import PDSDialog from '../../components/common/PDSDialog';
import {useTranslation} from 'react-i18next';
import {RESERVATION_HID_STATE_NEW} from '../../utils/listitem';
import dayjs from 'dayjs';
import ReservationDetail from "./ReservationCard";
import {RESERVATION_STATUSES} from "../../utils/constants";
import Typography from '@mui/material/Typography';

const ReservationList = (props) => {

    const {i18n} = useTranslation();

    const {
        userUseCases,
        reservations,
        handleEdit,
        handleDelete,
        setShowReservationItem,
        sortingCriterium = 1,
        all = false,
        showAll,

    } = props;

    const {t} = useTranslation();

    const [showDelete, setShowDelete] = useState(false);
    const [reservationToDelete, setReservationToDelete] = useState();
    const [extendedReservations, setExtendedReservations] = useState([]);


    const handleEditRow = (reservationId) => () => {
        handleEdit(reservationId);
    }

    const handleDeleteLoc = (reservation) => {
        setShowDelete(false);
        handleDelete(reservation.reservationId);
    }

    useEffect(() => {
        if (reservations) {
            const curr = dayjs();
            const extendedReservations = [];
            reservations.forEach((res) => {
                const copyOfRes = {...res};
                copyOfRes.dateFromD = dayjs(res.dateFrom);
                copyOfRes.dateToD = dayjs(res.dateTo);
                copyOfRes.status = copyOfRes.dateFromD.isAfter(curr) ? RESERVATION_STATUSES.PLANNED : copyOfRes.dateToD.isBefore(curr) ? RESERVATION_STATUSES.OLD :
                    RESERVATION_STATUSES.IN_PROGRESS;
                if (showAll || copyOfRes.status !== RESERVATION_STATUSES.OLD) {
                    extendedReservations.push(copyOfRes);
                }
            });
            setExtendedReservations(sort(extendedReservations, sortingCriterium));

        }
    }, [reservations, sortingCriterium, showAll]);

    const sort = (extendedReservations, sortingCriterium) => {
        const getStatusNumber = (status) => {
            if (sortingCriterium === 1) {
                if (status === RESERVATION_STATUSES.OLD) {
                    return 1;
                } else if (status === RESERVATION_STATUSES.PLANNED) {
                    return 0;
                } else {
                    return -1;
                }
            } else if (sortingCriterium === 2) {
                if (status === RESERVATION_STATUSES.IN_PROGRESS) {
                    return 1;
                } else if (status === RESERVATION_STATUSES.PLANNED) {
                    return 0;
                } else {
                    return -1;
                }
            } else {
                if (status === RESERVATION_STATUSES.PLANNED) {
                    return 1;
                } else if (status === RESERVATION_STATUSES.IN_PROGRESS) {
                    return 0;
                } else {
                    return -1;
                }
            }
        }

        return extendedReservations.sort(
            function (res1, res2) {
                return getStatusNumber(res1.status) - getStatusNumber(res2.status);

            }
        );
    }

    const isEditable = (item) => item.state === RESERVATION_HID_STATE_NEW && new Date(item.dateFrom).getTime() > new Date().getTime();

    const renderReservationCards = () => {
        return extendedReservations.map((item) => (
            <Grid item xs={12} sm={8} md={6} lg={4} xl={3} key={item.reservationId} sx={{padding: 2}}>
                <ReservationDetail all={all} handleEdit={handleEdit} handleDelete={handleDelete} userUseCases={userUseCases} reservation={item}/>
            </Grid>

        ));
    }
    
    const renderReservationEpmy = () => {
        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
                <img src='/tumbleweed.png' height='200px' />
                <Typography sx={{ typography: { md: 'h5', xs: 'body2' } }} style={{marginTop:'20px'}}>{t('NO_RESERVATION')}</Typography>
            </div>
    }

    return (
        <Grid container sx={{marginTop: "10px", overflow: 'auto', height: "calc(100vh - 200px)"}}>
            {reservations && extendedReservations && extendedReservations.length > 0 && renderReservationCards()}
            {(!reservations || !extendedReservations || extendedReservations.length === 0) && renderReservationEpmy()}

            {
                showDelete &&
                <PDSDialog
                    open={showDelete}
                    title={t('DELETE_CONFIRMATION_TITLE')}
                    onClose={() => {
                        setShowDelete(false)
                        setReservationToDelete(null);
                    }}
                    size="sm"
                    height={300}
                    onSubmit={() => {
                        handleDeleteLoc(reservationToDelete);
                    }}
                    actionType={'delete'}
                >
                    <div>
                        {t('DELETE_CONFIRMATION_MESSAGE', {reservationName: reservationToDelete.name})}
                    </div>
                </PDSDialog>
            }

        </Grid>


    )
        ;
}

export default ReservationList;