import httpService from './http.service';

const createGroup = (group) => httpService
    .post(`/groups`, group)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });


const updateGroup = (group) => httpService
    .put(`/groups/${group.groupId}`, group)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));


const getGroups = () => httpService
    .get(`/groups`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    getGroups,
    createGroup,
    updateGroup,

};