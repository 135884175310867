import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import Add from "@mui/icons-material/Add";
import {isComponentEnabledForUser, isComponentVisibleForUser} from '../../utils';
import {UC0100, UC0105} from '../../utils/constants';
import ToolBarCommon from "../../components/common/ToolBarCommon";
import {FormControlLabel, Switch} from "@mui/material";


const ToolBarSimple = (props) => {
    const {
        tabbedVersion,
        user,
        userUseCases,
        hadleNewReservation,
        sortingCriterium,
        setSortingCriterium,
        all,
        handleShowAll,
        showAll,
    } = props;

    const {t} = useTranslation();
    const [shown, setShown] = React.useState(true);


    return (
        <ToolBarCommon shown={shown} hideShowFeature={false} setShown={setShown} title={all ? t('ALL_RESERVATION') : t('MY_RESERVATION')}>

            <Grid item xs={10} sx={{display: 'flex', paddingTop: '0 !important', justifyContent: 'flex-start'}}>
                <FormControlLabel control={<Switch
                    checked={!showAll}
                    onChange={handleShowAll}
                    inputProps={{'aria-label': 'controlled'}}

                />}
                                  label={t('ACTIVE')}

                />
            </Grid>


            <Grid item xs={2} sx={{display: 'flex', paddingTop: '0 !important', justifyContent: 'flex-end'}}>
                {((all && isComponentVisibleForUser(UC0105, userUseCases)) || (!all && isComponentVisibleForUser(UC0100, userUseCases))) &&
                    <div>
                        <IconButton
                            edge="start"
                            aria-label="add reservation"
                            onClick={hadleNewReservation}
                            size="large"
                            disabled={!((all && isComponentEnabledForUser(UC0105, userUseCases)) || (!all && isComponentEnabledForUser(UC0100, userUseCases)))}>
                            <Add/>
                        </IconButton>
                    </div>
                }
            </Grid>
        </ToolBarCommon>
    );
}

export default ToolBarSimple;