import React from 'react';
import {useTranslation} from 'react-i18next';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/system";
import {Autocomplete, Checkbox, Chip, FormControlLabel} from "@mui/material";
import GroupAutocomplete from "../../common/GroupAutocomplete";
import {PLACE_MANAGER} from "../../../utils/constants";


const FormFields = styled('div')(({theme}) => ({
    height: '100%',
    width: '100%',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingTop: '50px',

}));

const EditorWrapper = styled('div')(({theme}) => ({
    height: '100%',
    width: '100%',
    padding: theme.spacing(3.25, 4, 4, 4),

}));


const ErrorMessage = styled('div')(({theme}) => ({
    fontSize: 13,

}));


function UserUpdate(props) {

    const {
        userUseCases,
        roles,
        groups,
        handleChange,
        updatedUser,
        error,
        readOnly,
        handleSelectedRole,
        handleSelectedGroup,
        handleSelectedManagedGroup,
    } = props;

    const {t} = useTranslation();

    return (
        <FormFields>
            <form autoComplete='off'>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            autoFocus
                            error={error.firstName}
                            helperText={error.firstName}
                            fullWidth
                            required
                            disabled={readOnly}
                            id="firstName"
                            value={updatedUser.firstName || ''}
                            onChange={handleChange('firstName', false)}
                            label={t('FIRST_NAME')}/>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            autoFocus
                            error={error.lastName}
                            helperText={error.lastName}
                            fullWidth
                            required
                            disabled={readOnly}
                            id="lastName"
                            value={updatedUser.lastName || ''}
                            onChange={handleChange('lastName', false)}
                            label={t('LAST_NAME')}/>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            autoFocus
                            error={error.userName}
                            helperText={error.userName}
                            fullWidth
                            required
                            disabled={true}
                            id="userName"
                            value={updatedUser.userName || ''}
                            onChange={handleChange('userName', false)}
                            label={t('LOGIN')}/>
                    </Grid>

                    <Grid item xs={4}>

                        <TextField
                            variant="outlined"
                            autoFocus
                            error={error.email}
                            helperText={error.email}
                            fullWidth
                            required
                            disabled={readOnly}
                            id="email"
                            value={updatedUser.email || ''}
                            onChange={handleChange('email', false)}
                            label={t('EMAIL')}/>
                    </Grid>


                    <Grid item xs={4}>

                        <TextField
                            variant="outlined"
                            autoFocus
                            fullWidth
                            disabled={readOnly}
                            id="licensePlate"
                            value={updatedUser.licensePlate || ''}
                            onChange={handleChange('licensePlate', false)}
                            label={t('LICENSE_PLATE')}/>
                    </Grid>

                    <Grid item xs={4}>

                        <TextField
                            variant="outlined"
                            autoFocus
                            fullWidth
                            disabled={readOnly}
                            id="phoneNumber"
                            value={updatedUser.phoneNumber || ''}
                            onChange={handleChange('phoneNumber', false)}
                            label={t('PHONE_NUMBER')}/>
                    </Grid>

                    <Grid item sm={4}>
                        <GroupAutocomplete
                            setGroups={handleSelectedGroup}
                            groups={groups}
                            selectedGroups={updatedUser.groups}
                        ></GroupAutocomplete>

                    </Grid>

                    <Grid item sm={4}>
                        <Autocomplete
                            multiple
                            disabled={readOnly}
                            value={updatedUser.roles || []}
                            onChange={(event, newValue) => {
                                handleSelectedRole(newValue);
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return option.value === value.value
                            }
                            }
                            filterSelectedOptions
                            options={roles || []}
                            getOptionLabel={(option) => {
                                return option.label;
                            }}
                            style={{width: '100%'}}
                            renderInput={(params) => (
                                <TextField variant="outlined" {...params} label={t('ROLE')} fullWidth/>
                            )}
                            renderTags={(value, getTagProps) => (
                                value.map((option, index) => {
                                        return <Chip
                                            style={{
                                                backgroundColor: option.lightColor,
                                                color: 'black'
                                            }}
                                            variant="outlined"
                                            label={option.label}
                                            {...getTagProps({index})}
                                        />
                                    }
                                )
                            )
                            }
                        />
                    </Grid>

                    {updatedUser.roles && updatedUser.roles.find(a => a.label == PLACE_MANAGER) != null &&
                        <Grid item sm={4}>
                            <GroupAutocomplete
                                label={t('PLACE_GROUPS_TO_MANAGE')}
                                setGroups={handleSelectedManagedGroup}
                                groups={groups}
                                selectedGroups={updatedUser.managedGroups}
                            ></GroupAutocomplete>
                        </Grid>}
                    <Grid item xs={4}>
                        <FormControlLabel labelPlacement="start" checked={updatedUser.active || ''}
                                          defaultChecked
                                          control={<Checkbox disabled={readOnly} onChange={(event) => {
                                              handleChange("active")(event)
                                          }}
                                                             sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                          label={t('USER_ACTIVE')}/>

                    </Grid>


                </Grid>
            </form>
        </FormFields>
    )
        ;
}

UserUpdate.propTypes = {};
UserUpdate.defaultProps = {};


export default UserUpdate;


