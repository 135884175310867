// Capitalization of string


// Get chip background color

function invertColor(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

// Get task state description
export const getErrorMessage = (error, t) => {

    const err = error?.response?.data?.message;
    if (err) {
        return t(err);
    } else {
        return t('UNKNOWN_ERROR');
    }
};

export const isComponentVisibleForUser = (componentUseCase, userUseCases) => {
    if (userUseCases) {
        let tmp = userUseCases[componentUseCase];
        return tmp;
    } else
        return true;
};

export const isComponentEnabledForUser = (componentUseCase, userUseCases) => {
    if (userUseCases)
        return userUseCases[componentUseCase] && userUseCases[componentUseCase].execute;
    else
        return true;
};

export const getCodeList = () => {

};

export const convertDateToISOString = date => {
    date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    date = date.toISOString().slice(0, 10);
    return date;
};

export const dayjsToDateString = (date, locale) => {
    return date.locale(locale).format("dd DD MMM YY");
}
export const dayjsToTimeString = (date, locale) => {
    return date.locale(locale).format('LT');
}

export const dayjsToDateTimeString = (date, locale) => {
    return date.locale(locale).format("dd DD MMM YY HH:mm");
}