import {combineReducers} from 'redux';

import authData from './auth';
import reservationData from './reservation';
import codelistData from './codelist';
import usersData from './users';
import useCasesData from './usecases';
import locationData from './location';
import assetData from './asset';
import roleData from './role';
import groupData from './group';
import settingData from './setting';


export default () => combineReducers({
    authData,
    reservationData,
    locationData,
    codelistData,
    usersData,
    useCasesData,
    assetData,
    roleData,
    groupData,
    settingData
});
