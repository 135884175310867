export default {
    // Set user info
    SET_USER_INFO: 'SET_USER_INFO',
    // Logout
    LOG_OUT: 'LOG_OUT',

    // Fetch code list
    ALL_CODE_FETCH_REQUEST: 'ALL_CODE_FETCH_REQUEST',
    ALL_CODE_FETCH_SUCCESS: 'ALL_CODE_FETCH_SUCCESS',
    ALL_CODE_FETCH_FAIL: 'ALL_CODE_FETCH_FAIL',
   
    // use cases
    USECASE_FETCH_REQUEST: 'USECASE_FETCH_REQUEST',
    USECASE_FETCH_SUCCESS: 'USECASE_FETCH_SUCCESS',
    USECASE_FETCH_FAIL: 'USECASE_FETCH_FAIL',

    // reservation
    RESERVATION_FETCH_REQUEST: 'RESERVATION_FETCH_REQUEST',
    RESERVATION_FETCH_SUCCESS: 'RESERVATION_FETCH_SUCCESS',
    RESERVATION_FETCH_FAIL: 'RESERVATION_FETCH_FAIL',

    RESERVATION_CREATE_REQUEST: 'RESERVATION_CREATE_REQUEST',
    RESERVATION_CREATE_SUCCESS: 'RESERVATION_CREATE_SUCCESS',
    RESERVATION_CREATE_FAIL: 'RESERVATION_CREATE_FAIL',

    RESERVATION_VALIDATE_REQUEST: 'RESERVATION_VALIDATE_REQUEST',
    RESERVATION_VALIDATE_SUCCESS: 'RESERVATION_VALIDATE_SUCCESS',
    RESERVATION_VALIDATE_FAIL: 'RESERVATION_VALIDATE_FAIL',

    RESERVATION_UPDATE_REQUEST: 'RESERVATION_UPDATE_REQUEST',
    RESERVATION_UPDATE_SUCCESS: 'RESERVATION_UPDATE_SUCCESS',
    RESERVATION_UPDATE_FAIL: 'RESERVATION_UPDATE_FAIL',

    RESERVATION_DELETE_REQUEST: 'RESERVATION_DELETE_REQUEST',
    RESERVATION_DELETE_SUCCESS: 'RESERVATION_DELETE_SUCCESS',
    RESERVATION_DELETE_FAIL: 'RESERVATION_DELETE_FAIL',

    RESERVATION_ERRORS_CLEAR: 'RESERVATION_ERRORS_CLEAR',

    // location


    LOCATION_CREATE_REQUEST: 'LOCATION_CREATE_REQUEST',
    LOCATION_CREATE_SUCCESS: 'LOCATION_CREATE_SUCCESS',
    LOCATION_CREATE_FAIL: 'LOCATION_CREATE_FAIL',

    LOCATION_UPDATE_REQUEST: 'LOCATION_UPDATE_REQUEST',
    LOCATION_UPDATE_SUCCESS: 'LOCATION_UPDATE_SUCCESS',
    LOCATION_UPDATE_FAIL: 'LOCATION_UPDATE_FAIL',

    LOCATION_ACTIVE_REQUEST: 'LOCATION_ACTIVE_REQUEST',
    LOCATION_ACTIVE_SUCCESS: 'LOCATION_ACTIVE_SUCCESS',
    LOCATION_ACTIVE_FAIL: 'LOCATION_ACTIVE_FAIL',

    LOCATION_FETCH_REQUEST: 'LOCATION_FETCH_REQUEST',
    LOCATION_FETCH_SUCCESS: 'LOCATION_FETCH_SUCCESS',
    LOCATION_FETCH_FAIL: 'LOCATION_FETCH_FAIL',

    LOCATION_AVAILABLE_FETCH_REQUEST: 'LOCATION_AVAILABLE_FETCH_REQUEST',
    LOCATION_AVAILABLE_FETCH_SUCCESS: 'LOCATION_AVAILABLE_FETCH_SUCCESS',
    LOCATION_AVAILABLE_FETCH_FAIL: 'LOCATION_AVAILABLE_FETCH_FAIL',

    ASSET_CREATE_REQUEST: 'ASSET_CREATE_REQUEST',
    ASSET_CREATE_SUCCESS: 'ASSET_CREATE_SUCCESS',
    ASSET_CREATE_FAIL: 'ASSET_CREATE_FAIL',

    ASSET_UPDATE_REQUEST: 'ASSET_UPDATE_REQUEST',
    ASSET_UPDATE_SUCCESS: 'ASSET_UPDATE_SUCCESS',
    ASSET_UPDATE_FAIL: 'ASSET_UPDATE_FAIL',

    ASSET_ACTIVE_REQUEST: 'ASSET_ACTIVE_REQUEST',
    ASSET_ACTIVE_SUCCESS: 'ASSET_ACTIVE_SUCCESS',
    ASSET_ACTIVE_FAIL: 'ASSET_ACTIVE_FAIL',

    ASSET_FETCH_REQUEST: 'ASSET_FETCH_REQUEST',
    ASSET_FETCH_SUCCESS: 'ASSET_FETCH_SUCCESS',
    ASSET_FETCH_FAIL: 'ASSET_FETCH_FAIL',

    ASSET_AVAILABLE_FETCH_REQUEST: 'ASSET_AVAILABLE_FETCH_REQUEST',
    ASSET_AVAILABLE_FETCH_SUCCESS: 'ASSET_AVAILABLE_FETCH_SUCCESS',
    ASSET_AVAILABLE_FETCH_FAIL: 'ASSET_AVAILABLE_FETCH_FAIL',
    
    // code list
    CODELIST_FETCH_REQUEST: 'CODELIST_FETCH_REQUEST',
    CODELIST_FETCH_SUCCESS: 'CODELIST_FETCH_SUCCESS',
    CODELIST_FETCH_FAIL: 'CODELIST_FETCH_FAIL',

    // users
    USERS_FETCH_REQUEST: 'USERS_FETCH_REQUEST',
    USERS_FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
    USERS_FETCH_FAIL: 'USERS_FETCH_FAIL',

    USERS_BY_ROLE_FETCH_REQUEST: 'USERS_BY_ROLE_FETCH_REQUEST',
    USERS_BY_ROLE_FETCH_SUCCESS: 'USERS_BY_ROLE_FETCH_SUCCESS',
    USERS_BY_ROLE_FETCH_FAIL: 'USERS_BY_ROLE_FETCH_FAIL',

    USER_CREATE_REQUEST: 'USER_CREATE_REQUEST',
    USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
    USER_CREATE_FAIL: 'USER_CREATE_FAIL',
    
    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAIL: 'USER_UPDATE_FAIL',


    GROUP_CREATE_REQUEST: 'GROUP_CREATE_REQUEST',
    GROUP_CREATE_SUCCESS: 'GROUP_CREATE_SUCCESS',
    GROUP_CREATE_FAIL: 'GROUP_CREATE_FAIL',

    GROUP_UPDATE_REQUEST: 'GROUP_UPDATE_REQUEST',
    GROUP_UPDATE_SUCCESS: 'GROUP_UPDATE_SUCCESS',
    GROUP_UPDATE_FAIL: 'GROUP_UPDATE_FAIL',

    GROUP_FETCH_REQUEST: 'GROUP_FETCH_REQUEST',
    GROUP_FETCH_SUCCESS: 'GROUP_FETCH_SUCCESS',
    GROUP_FETCH_FAIL: 'GROUP_FETCH_FAIL',

    SETTING_UPDATE_REQUEST: 'SETTING_UPDATE_REQUEST',
    SETTING_UPDATE_SUCCESS: 'SETTING_UPDATE_SUCCESS',
    SETTING_UPDATE_FAIL: 'SETTING_UPDATE_FAIL',

    SETTING_FETCH_REQUEST: 'SETTING_FETCH_REQUEST',
    SETTING_FETCH_SUCCESS: 'SETTING_FETCH_SUCCESS',
    SETTING_FETCH_FAIL: 'SETTING_FETCH_FAIL',

    ROLES_FETCH_REQUEST: 'ROLES_FETCH_REQUEST',
    ROLES_FETCH_SUCCESS: 'ROLES_FETCH_SUCCESS',
    ROLES_FETCH_FAIL: 'ROLES_FETCH_FAIL',

};
